import PropTypes from 'prop-types';
import NewsletterDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/NewsletterDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const NewsletterModule = ({ isFooter = false, isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                borderTop: isFooter ? '1px solid var(--color-wrapper-border-newsletter)' : 'none',
                marginBottom: isFooter || isGrid ? 0 : 'var(--margin-bottom-module)',
            })}
        >
            <NewsletterDataTransformerBlock {...rest} />
        </section>
    );
};

NewsletterModule.propTypes = {
    isFooter: PropTypes.bool,
    isGrid: PropTypes.bool,
};

export default NewsletterModule;
